.page-head {
	padding-top: 9rem;
	padding-bottom: 1rem;
	margin-bottom: 1rem;

	.page-title {
		font-weight: 800;
		font-size: 1.8rem;
		color: $secondary;
		line-height: 35px;
	}

	.breadcrumb {}

	.page-right-actions {
		display: flex;
		justify-content: flex-end;

		.button {
			margin-left: 1rem;
		}
	}
}


@media screen and (max-width : 767px) {

	.navbar-collapse {
		&.show {
			.navbar-nav {
				margin-top: 10px !important;
			}
		}
	}

	.page-head {
		padding-top: 11rem;

		.page-right-actions {
			display: flex;
			justify-content: space-between;
			margin-top: 1rem;

			.button {
				display: flex;
				justify-content: space-between;
				text-align: center;
				font-size: .9rem;
				margin-left: 0;
			}

		}
	}

}