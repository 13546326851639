.page {
    box-sizing: border-box;
    width: 210mm;
    min-height: 277mm;
    padding: 0;
    margin: auto;
    background-color: #fff;
    box-shadow: 0 0 5mm rgba(0, 0, 0, 0.1);
    transform-origin: 50% 50%;
    color: #000;
    page-break-after: always;
    font-family: Tahoma, "Trebuchet MS", sans-serif;

    &:last-child {
        page-break-after: auto;
    }

    &.legal {
        width: 215.9mm;
        min-height: 355.6mm;
        &.landscape {
            width: 355.6mm;
            min-height: 215.9mm;
        }
    }

    &.a4 {
        &.landscape {
            width:  297mm;
            min-height:  210mm;
        }
    }

    .content {
        box-sizing: border-box;
        width: 100%;
        min-height: 277mm;
        padding: 4mm;
        margin: 0;
        background-color: #fff;

        table {
            thead {
                display: table-header-group;
               
            }
        }
    }
}

// .table-bordered>:not(caption)>*>* {
//     border-width: 0;
// }
.page-break {
    page-break-before: always;
}

.page-break-after {
    page-break-after: always;
}

.table-print.table-sm {
    border-color: #000;
    border-top-color: transparent;
    border-right-color: transparent;
    border-left-color: transparent;
    td,th {
        font-size: 11px !important;
        padding: 1px 2px !important;
        vertical-align: middle;
        color: #000;
    }
}

.table-payslip {
    thead {
        td {
            font-weight: bold;
        }
    }
    td {
        border: 1px solid;
        padding: 3px;
    }
}


.print-main-table {
    width: 100%;
    
    .table-print {
        thead {
            background-color: rgba(0, 0, 0, 0.1);
        }

        &.table-no-border {
            border: none;
            th,td {
                border: none;
            }
        }


        &.table-border {
            th,td {
                border: 1px solid gray;
            }
        }
        th,td {
            font-size: 12px;
            padding: 2px 2px;
            
        }
        
    }
    
}

@media print {

    
    body {
        background-color: #FFF;
    }
    .page {
        box-shadow: none;
        size: auto;
    }

    .page-break {
        page-break-before: always !important;
    }
    
    
    .page-break-after {
        page-break-after: always !important;
    }

    .print-main-table {
        > thead {
            td {
               padding: 0; 
            }
        }
        > tbody {
            td {
                padding: 0;
            }
        }
    }
    // tr{
    //     border: 1px solid black;
    // }
   
    .content {
        // top: 80px;
        // position: relative;
        table {
            thead {
                display: table-header-group !important;
                
            }
        }
    }

    body {
        padding: 0 !important;
    }
}

@page {
    size: auto !important;
  }
