.clander {
    width: 14.2857142857%;
    height: 150px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #cecece;

    h3 {
        font-weight: 600;
        font-size: 2.5rem;
        margin: 0;
    }
}

.days_name {
    width: 14.2857142857%;
    height: 150px;
    display: inline-flex;
    border: 1px solid #cecece;
    flex-direction: column;
    position: relative;

    .num {
        text-align: right;

        span {
            text-align: right;
            font-weight: 600;
            font-size: 2.5rem;
            padding: 4px;
            color: $secondary;
        }
    }

    .detail {
        list-style: none;
        display: flex;
        flex-wrap: wrap;
        padding: 0;
        margin: 0;
        position: absolute;
        bottom: 6px;
        left: 4px;

        li {
            width: 46%;
            margin: 2px;
            padding: 2px;
            text-align: center;
            border-radius: 4px;
            color: white;
        }

        a {
            width: 100%;
            color: #ff3a33;

            &:hover {
                color: $secondary;
            }
        }

        .P {
            color: #ff3a33;
            border-radius: 5px;
            padding: 0.2rem;
            margin: 2px 0px;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        .p {
            background-color: #0AD4A0;
        }

        .a {
            background-color: #F5466C;
        }

        .l {
            background-color: #F9AC49;
        }

        .lc {
            background-color: $secondary;
        }
    }
}



.graph {
    .heading {
        font-size: 16px;
        font-weight: 700;
        text-transform: uppercase;
    }

    .dash-bg-card {
        border-radius: 16px;
        border: 1px solid rgba(0, 0, 0, 0.1);
        background: #FFF;
        box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.1), 0px 0px 5px 0px rgba(0, 0, 0, 0.1);
        padding: 24px;
        padding-bottom: 44px;

        .graph-bar {
            padding-bottom: 33px;
            height: 360px;
        }
    }

    .dash-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 20px;



        .menu {
            display: flex;
            align-items: center;
            gap: 12px;

            .menu-link {
                list-style-type: none;
                display: flex;
                flex-direction: row;
                align-items: center;
                padding: 0;
                height: 33px;
                overflow: hidden;
                border-radius: 25px;
                border: 1px solid rgba(0, 0, 0, 0.1);
                margin: 0;

                li {
                    position: relative;

                    a {

                        border-radius: 50px;
                        font-size: 12px;
                        font-weight: 500;
                        padding: 10px 15px;
                        color: #000;
                        cursor: pointer;

                        &.active {
                            background: $primary;
                            color: $themeLight;
                        }

                    }

                    .menu-date {
                        visibility: hidden;
                        width: 0;
                        max-width: 0;
                        min-width: 0;
                        position: absolute;
                        left: 0;
                        bottom: 0;
                        cursor: pointer;
                    }

                    >button {
                        background: transparent;
                        border: none;
                        padding: 10px 8px;
                    }

                    svg {
                        width: 20px;
                    }
                }
            }

            .menu-icon {
                list-style-type: none;
                display: flex;
                flex-direction: row;
                align-items: center;
                padding: 0;
                height: 33px;
                overflow: hidden;
                border-radius: 25px;
                border: 1px solid rgba(0, 0, 0, 0.1);
                margin: 0;

                li {
                    position: relative;

                    a {
                        padding: 10px 15px;
                        border-radius: 50px;
                        font-size: 12px;
                        font-weight: 600;

                        color: #000;
                        cursor: pointer;

                        &.active {
                            background: rgba(255, 0, 43, 0.05);
                            color: $primary;
                        }
                    }

                    svg {
                        width: 20px;
                    }
                }
            }
        }
    }
}


.staff-attendance {
    .icon {
        a {
            margin: 0;
            background-color: #003970;
            padding: 6px;
            border-radius: 33px;
            color: #fbfbfb;
            i{
                font-size: 18px;
            }
        }
    }

    button {
        background: transparent;
        border: none;
        padding: 10px 6px;
        font-size: 18px;
    }

    svg {
        width: 20px;
    }
    .icon{
        display: flex;
        justify-content: flex-end;
        a{
            height: 32px;
            width: 32px;
            padding: 4px 6px;
            margin: 8px;
        }
        .form-group{
            display: flex;
            align-items: center;
        }
    }
}

@media screen and (max-width : 767px) {
    
.staff-attendance {
    
    .icon{
       justify-content: center;
    }
}
}