footer {
	background-color: $lightGray;
	position: fixed;
	padding: .4rem 0;
	bottom: 0;
	width: 100%;
	font-size: .8rem;
	.copyright {
		border-top: 1px solid rgba(0, 0, 0, 0.1);
		padding: 1rem 0;
		margin-top: 1rem;
		.row {
			align-items: center;
		}
		.footer-social {
			margin: 0;
			padding: 0;
			list-style: none;
			display: flex;
			align-items: center;
			justify-content: flex-end;
			li {
				margin-left: 1rem;
				font-size: 1.5rem;
				a {
					@include textBlack(0.5);
				}
			}
		}
	}
}





@media screen and (max-width: 767px) { 
	footer{
		position: relative;
		text-align: center !important;
		margin: 10px 0;
	}
	.call-to-action {
		padding: 2.75rem;
		text-align: center;
		.cta-button {
			text-align: center;
		}
	}

}