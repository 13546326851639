.exam-summary {
    .exam-summary-tittle {
        display: flex;
    }

    .icon {
        button {
            background-color: $primary;
            padding: 2px;
            margin-right: 11px;
            border-radius: 33px;
            color: $themeLight;
            border: none;
            height: 30px;
            width: 30px;

            &:hover {
                background: $secondary;
            }

            i {
                font-size: 18px;
            }

        }
    }

}

.exam-summary-card {
    background-color: rgba(0, 149, 223, 0.1);
    padding: 28px 33px;
    border-radius: 8px;

    .exam-summary-tittle {
        display: flex;
    }

    .exam-summary-content {
        margin-left: 18px;

        p {
            color: rgba(0, 0, 0, 0.5);
            margin-bottom: 0;
        }

        h3 {
            color: $themeDark;
            font-size: 22px;
            margin-bottom: 0;
        }

        span {
            color: rgba(0, 0, 0, 0.5);
            font-size: 12px;
        }
    }

    .exam-summary-info {
        display: flex;
        padding: 0;
        margin-top: 44px;
        margin-bottom: 0;

        li {
            color: rgba(0, 0, 0, 0.5);
            list-style: none;
            font-size: 14px;
            margin-right: 22px;
            background-color: white;
            padding: 11px 22px;
            border-radius: 22px;

            i {
                margin-right: 11px;
            }

            span {
                color: $primary;
                font-size: 14px;
                font-weight: bold;
            }
        }
    }

}

.left {

    .exam-section-list {
        h3 {
            font-size: 14px;
            margin: 0;
            font-weight: bold;
        }

        p {
            font-size: 12px;
        }

    }
}

.list-info-boxes {

    .icon {
        a {
            background-color: $primary;
            padding: 2px 6px;
            border-radius: 33px;
            color: $themeLight;
            height: 30px;
            width: 30px;
            display: flex;
            text-align: center;
            font-size: 12px;
            align-content: center;
            justify-content: center;
            align-items: center;

            &:hover {
                background: $secondary;
            }

            i {
                font-size: 18px;
            }

        }
    }
}

.admission-status {
    text-align: center;

    p {
        font-size: 22px;
        margin: 0;
    }

    h1 {
        font-weight: 700;
        font-size: 30px;
        text-transform: uppercase;
        color: $secondary;
    }

    img {
        width: 70px;
    }
}




.Exam-body {
    height: 500px;
    overflow: scroll;

    .exam-heading {
        border-bottom: 1px solid rgba(78, 77, 77, 0.178);
        padding-bottom: 8px;
        color: #0095DF;
        font-size: 18px;
    }

    .exam-section-list {
        h3 {
            font-size: 14px;
            margin: 0;
            font-weight: bold;
        }

        p {
            font-size: 12px;
        }

    }

    .list-info-boxes {

        .icon {
            a {
                background-color: $primary;
                padding: 2px 6px;
                border-radius: 33px;
                color: $themeLight;
                height: 30px;
                width: 30px;
                display: flex;
                text-align: center;
                font-size: 12px;
                align-content: center;
                justify-content: center;
                align-items: center;

                &:hover {
                    background: $secondary;
                }

                i {
                    font-size: 18px;
                }

            }
        }
    }
}

@media screen and (max-width : 767px) {
    .exam-summary-card {
       overflow-y: hidden;
       overflow-x: scroll;
    }
}