.input-icon {
    position: relative;

    .form-control {
        min-height: 52px;
        border-radius: 50px;
        padding-left: 4rem;
        color: $primary;

        &:focus {
            outline: 1px solid $primary;
            box-shadow: none;
        }
    }

    span {
        width: 38px;
        height: 38px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: rgba(215, 36, 29, 0.2);
        position: absolute;
        left: 10px;
        top: 8px;
        border-radius: 50%;
        color: $themeDark;
        font-size: 1.6rem;
    }
}

.form-control,
.form-select {
    &:focus {
        outline: 1px solid $primary;
        box-shadow: none;
    }

    &:focus~label {
        color: $primary;
    }
}


.form-group {
    label {
        margin-bottom: 0.3rem;
        @include textBlack(0.9);
        font-weight: 500;
        font-size: .9rem;
    }

    .form-control,
    .form-select {
        border-radius: 0;
        min-height: 38px;

        &:placeholder {
            @include textBlack(0.1);
        }
    }
}

.form-section-title {
    font-weight: bold;
    font-size: 1.2rem;
}

.add-student-form {
    .form-group {
        margin-bottom: 2rem;
    }
}

.card-form-wrap {
    padding: 2rem 2rem;
}

.form-wrap {
    position: relative;
    border: 1px solid rgba(0, 0, 0, 0.1);
    padding: 2rem;

    .row {
        margin: 1rem 0rem 1rem 0rem;
        align-items: center;

        .label-value {
            background-color: rgba(0, 0, 0, 0.02);
            min-height: 2.3rem;
            border: 1px solid #ced4da;
            border-radius: 0.25rem;
            display: flex;
            align-items: center;
            padding: 0.375rem 0.75rem;
        }
    }

    .form-control,
    .form-select {
        background-color: rgba(0, 0, 0, 0.02);
    }

    .col-form-label {
        text-align: right;
    }

    .form-legend {
        position: absolute;
        display: inline-block;
        background-color: #FFF;
        padding: 0.4rem 1rem;
        top: -22px;
        left: 25.5%;
        font-weight: bold;
        font-size: 1.2rem;
        color: $primary;
    }

    &.label-left {
        .form-legend {
            left: 1.5%;
        }
    }
}

.form-style-1 {

    .form-group {
        label {
            font-size: .8rem;
            font-weight: 600;
            margin-bottom: 0.6rem;
        }
    }

    .form-control,
    .form-select {
        background-color: #F2F7FA;
        border-color: rgba(0, 0, 0, 0.1);
        min-height: 40px;
    }

    input {
        &::-webkit-input-placeholder {
            /* Chrome/Opera/Safari */
            color: rgba(0, 0, 0, 0.3);
        }

        &::-moz-placeholder {
            /* Firefox 19+ */
            color: rgba(0, 0, 0, 0.3);
        }

        &:-ms-input-placeholder {
            /* IE 10+ */
            color: rgba(0, 0, 0, 0.3);
        }

        &:-moz-placeholder {
            /* Firefox 18- */
            color: rgba(0, 0, 0, 0.3);
        }
    }
}
.label-gray {
    background-color: rgba(0, 0, 0, 0.02);
    min-height: 2.7rem;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    display: flex;
    align-items: center;
    padding: 0.375rem 0.75rem;
}
.dropdown-item.active, .dropdown-item:active{
    background-color: $primary;
}
@media screen and (max-width:767px) {
    .form-wrap {
        padding: 0;
    
        .row {
            align-items: center;
    
            .label-value {
                background-color: rgba(0, 0, 0, 0.02);
                min-height: 2.3rem;
                border: 1px solid #ced4da;
                border-radius: 0.25rem;
                display: flex;
                align-items: center;
                padding: 0.375rem 0.75rem;
            }
        }
    
        .col-form-label {
            text-align: left;
        }
    
        .form-legend {
            top: -14px;
            left: 10px;
        }
    
        &.label-left {
            .form-legend {
                left: 1.5%;
            }
        }
    }
}