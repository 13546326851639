@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700;800;900&display=swap');



@font-face {
    font-family: 'Noto Nastaliq Urdu';
    src: url('fonts/Noto_Nastaliq_Urdu/NotoNastaliqUrdu-Bold.woff2') format('woff2'),
        url('fonts/Noto_Nastaliq_Urdu/NotoNastaliqUrdu-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Noto Nastaliq Urdu';
    src: url('fonts/Noto_Nastaliq_Urdu/NotoNastaliqUrdu-Regular.woff2') format('woff2'),
        url('fonts/Noto_Nastaliq_Urdu/NotoNastaliqUrdu-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Noto Nastaliq Urdu';
    src: url('fonts/Noto_Nastaliq_Urdu/NotoNastaliqUrdu-Medium.woff2') format('woff2'),
        url('fonts/Noto_Nastaliq_Urdu/NotoNastaliqUrdu-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Noto Nastaliq Urdu';
    src: url('fonts/Noto_Nastaliq_Urdu/NotoNastaliqUrdu-SemiBold.woff2') format('woff2'),
        url('fonts/Noto_Nastaliq_Urdu/NotoNastaliqUrdu-SemiBold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Noto Nastaliq Urdu';
    src: url('fonts/Noto_Nastaliq_Urdu/NotoNastaliqUrdu-Regular.woff2') format('woff2'),
        url('fonts/Noto_Nastaliq_Urdu/NotoNastaliqUrdu-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}



.font-ur {
    font-family: 'Noto Nastaliq Urdu' !important;
}